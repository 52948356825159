import { FC, useEffect } from 'react'

import { fetchPartnerLinkAction } from 'actions/system/fetchPartnerLinkAction'
import { internalServerErrorPath } from 'components/paths'
import { fullPageReload } from 'functions/fullPageReload'
import { replace } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const LegacyPartnerLink: FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const process = async () => {
      const response = await dispatch(fetchPartnerLinkAction())

      if ('result' in response && response.result?.link) {
        fullPageReload(response.result.link)
      } else {
        dispatch(replace(internalServerErrorPath))
      }
    }

    process().catch(console.error)
  }, [dispatch])

  return null
}

export default LegacyPartnerLink
